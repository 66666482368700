import React, { Fragment } from 'react';
import { CandidateListingFilters, DEFAULT_FILTER_STATE } from '../Utils';
import { JobStagesFilter } from './JobStagesFilter';
import { Button, Row } from 'reactstrap';
import { JobsFilter } from './JobsFilter';
import { StatusFilter } from './StatusFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { IconButton } from '../../../../../../../components/IconButton';

interface PropTypes {
  filters: CandidateListingFilters;
  onSearch: () => void;
  setFilters: (filters: CandidateListingFilters) => void;
}

export function FiltersBody(props: PropTypes) {
  const defaultFilters: JSX.Element[] = [
    <JobsFilter
      key='jobs-filter'
      isLoading={false}
      filters={props.filters}
      setFilters={props.setFilters}
    />,
    <JobStagesFilter
      key='job-stages-filter'
      isLoading={false}
      filters={props.filters}
      setFilters={props.setFilters}
    />,
    <StatusFilter
      key='status-filter'
      isLoading={false}
      filters={props.filters}
      setFilters={props.setFilters}
    />,
    <DateRangeFilter
      key='date-range-filter'
      isLoading={false}
      filters={props.filters}
      setFilters={props.setFilters}
    />,
  ];

  return (
    <div className='mt-6h'>
      <Row className='gx-3'>
        {defaultFilters.map((filter, index) => (
          <Fragment key={index}>{filter}</Fragment>
        ))}
      </Row>
      <div className='d-flex justify-content-end mt-6h'>
        <Button
          color='danger'
          onClick={() => props.setFilters(DEFAULT_FILTER_STATE)}
        >
          Reset
        </Button>
        <IconButton
          iconName='bi-search'
          buttonText='Search'
          color='primary'
          buttonClass='ms-3'
          onClick={props.onSearch}
        />
      </div>
    </div>
  );
}
