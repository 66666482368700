import React, { useEffect, useRef, useState } from 'react';
import {
  AlertMessage,
  ListCandidatesPageHeader,
} from './ListCandidatesPageHeader';
import {
  ListCandidates,
  ListCandidate,
} from '../../../../entities/applicant_tracking/ListCandidate';
import { Job } from '../../../../entities/applicant_tracking/Job';
import { JobStageTemplate } from '../../../../entities/applicant_tracking/JobStageTemplate';
import ListPageEmptyState from '../../ListPageEmptyState';
import { ListCandidateService } from '../../../../services/applicant_tracking/ListCandidatesService';
import { User } from '../../../../entities/User';
import { Alert, AlertObject } from '../../../../components/Alert';
import { HrisEmployeesService } from '../../../../services/applicant_tracking/HrisEmployeesService';
import {
  HrisEmployee,
  HrisEmployees,
} from '../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { EmailAccountIntegration } from '../../../../entities/EmailAccountIntegration';
import { NewListCandidatesPageBody } from './ListCandidatesPageBody/NewListCandidatesPageBody';
import {
  CandidateListingFilters,
  DEFAULT_FILTER_STATE,
  resetPageIfFiltersChanged,
} from './ListCandidatesPageBody/ListCandidateFilters/Utils';

interface PropTypes {
  alertMessage: AlertMessage;
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  hrisProvider: HrisProvider;
  jobs: Job[];
  jobStages: JobStageTemplate[];
  policies: CandidatePolicies;
  totalCandidates: number;
  bulkSelfSchedulingEnabled: boolean;
}

export interface CandidatePolicies {
  addCandidateEnabled: boolean;
}

export type ListCandidatesFilterTab =
  | 'all'
  | 'active'
  | 'hired'
  | 'rejected'
  | 'draft';

export default function NewListingPage(props: PropTypes) {
  const requestsCount = useRef(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCandidates, setSelectedCandidates] = useState<ListCandidate[]>(
    [],
  );
  const [totalFilteredCandidates, setTotalFilteredCandidates] =
    useState<number>(0);
  const [candidates, setCandidates] = useState<ListCandidate[]>([]);
  const [hrisEmployees, setHrisEmployees] = useState<HrisEmployee[]>([]);
  const [filterStates, setFilterStates] =
    useState<CandidateListingFilters>(DEFAULT_FILTER_STATE);
  const [previousFilterStates, setPreviousFilterStates] =
    useState<CandidateListingFilters>(filterStates);
  const [alert, setAlert] = useState<AlertObject>(null);

  const loadCandidates = () => {
    const currentRequestCount = ++requestsCount.current;
    setIsLoading(true);

    ListCandidateService.newList(filterStates).then(
      (response: ListCandidates) => {
        const { candidates, entriesCount } = response;
        const applicationIds = candidates.map((c) => c.application?.id);
        if (currentRequestCount != requestsCount.current) return;

        setCandidates(candidates);
        setTotalFilteredCandidates(entriesCount);

        applicationIds.length > 0 &&
          HrisEmployeesService.list({ applicationIds }).then(
            (response: HrisEmployees) => {
              setHrisEmployees(response.employees);
            },
          );

        setIsLoading(false);
      },
    );
  };

  const performSearch = () => {
    resetPageIfFiltersChanged(
      previousFilterStates,
      filterStates,
      setFilterStates,
    );
    setPreviousFilterStates(filterStates);
    loadCandidates();
    setSelectedCandidates([]);
  };

  const reloadPage = () => {
    setSelectedCandidates([]);
    loadCandidates();
  };

  useEffect(performSearch, [filterStates.sortingColumn, filterStates.page]);

  return props.totalCandidates === 0 ? (
    <ListPageEmptyState
      title={'No candidates added yet'}
      text={'Create your first candidate to start using it on your ATS.'}
      addButtonText={'Add Candidate'}
      onClickHref={'/applicant_tracking/candidate_wizard'}
      disabled={!props.policies.addCandidateEnabled}
    />
  ) : (
    <>
      {alert?.message && (
        <Alert
          type={alert.type}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
      )}
      <ListCandidatesPageHeader
        alertMessage={props.alertMessage}
        currentUser={props.currentUser}
        candidates={candidates}
        selectedCandidates={selectedCandidates}
        addCandidateEnabled={props.policies.addCandidateEnabled}
        emailAccountIntegration={props.emailAccountIntegration}
        reloadPage={reloadPage}
        setAlert={setAlert}
        hrisEmployees={hrisEmployees}
        hrisProvider={props.hrisProvider}
        bulkSelfSchedulingEnabled={props.bulkSelfSchedulingEnabled}
      />
      <NewListCandidatesPageBody
        candidates={candidates}
        currentUser={props.currentUser}
        emailAccountIntegration={props.emailAccountIntegration}
        filterStates={filterStates}
        hrisEmployees={hrisEmployees}
        hrisProvider={props.hrisProvider}
        isLoading={isLoading}
        selectedCandidates={selectedCandidates}
        totalFilteredCandidates={totalFilteredCandidates}
        onSearch={performSearch}
        setSelectedCandidates={setSelectedCandidates}
        onFilterStatesUpdate={setFilterStates}
        reloadPage={reloadPage}
        setAlert={setAlert}
      />
    </>
  );
}
