import React from 'react';
import { AddFilterButton } from './AddFilterButton';
import { SegmentSelect } from './SegmentSelect';
import { ToggleFiltersButton } from './ToggleFiltersButton';
import { CandidateListingFilters } from '../Utils';

interface PropTypes {
  isFiltersOpen: boolean;
  filters: CandidateListingFilters;
  setIsFiltersOpen: (value: boolean) => void;
}

export function FiltersHeader(props: PropTypes) {
  return (
    <div className='d-flex justify-content-between'>
      <AddFilterButton />
      <div className='d-flex'>
        <ToggleFiltersButton
          filters={props.filters}
          isFiltersOpen={props.isFiltersOpen}
          setIsFiltersOpen={props.setIsFiltersOpen}
        />
        <SegmentSelect />
      </div>
    </div>
  );
}
