import React from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';
import { Placement } from '../../utils/types/Placement';
import { DateFilter, DateRange, DateRangePicker } from '../DateRangePicker';

interface PropTypes {
  /** Classes to apply to the wrapping element. */
  className?: string;
  /** Label. */
  label: string;
  /** Label placement: top, bottom, start, end. defaults to 'top' */
  labelPlacement?: Placement;
  /** Classes to apply to the label element. */
  labelClassName?: string;
  /** The startDate and the endDate filters. */
  dateFilter: DateFilter;
  clearable?: boolean;
  iconName?: string;
  placeholder?: string;
  /** Called when the input changes, with the newly selected options. */
  onChange: (start: Moment, end: Moment, range: DateRange) => void;
}

/**
 * Labelled date picker component.
 */
export function LabelledDatePicker(props: PropTypes) {
  const labelPlacement = props.labelPlacement ?? 'top';

  return (
    <div className={classNames(props.className)}>
      {labelPlacement == 'top' && (
        <>
          <div className='px-1 mb-2 d-flex align-items-end'>
            <label className={classNames('me-auto', props.labelClassName)}>
              {props.label}
            </label>
          </div>
          <DateRangePicker {...props} />
        </>
      )}
      {labelPlacement == 'start' && (
        <div className='px-1 d-flex align-items-center'>
          <label className='text-nowrap me-2'>{props.label}</label>
          <DateRangePicker {...props} />
        </div>
      )}
    </div>
  );
}
