import { ApiService } from '../../ApiService';
import { ListCandidates } from '../../../entities/applicant_tracking/ListCandidate';
import {
  FilterStates,
  mapToQueryParams,
} from '../../../containers/applicant_tracking/Candidates/ListCandidatesPage/FilterStates';
import { CandidateListingFilters } from '../../../containers/applicant_tracking/Candidates/ListCandidatesPage/ListCandidatesPageBody/ListCandidateFilters/Utils';
import snakecaseKeys from 'snakecase-keys';

function mapToParams(filters: CandidateListingFilters) {
  return {
    status: filters.status,
    jobStages: filters.jobStages.map((stage) => stage.value),
    jobs: filters.jobs.map((job) => job.value),
    startDate: filters.dateRange.start?.utc()?.format(),
    endDate: filters.dateRange.end?.utc()?.format(),
    dateRange: filters.dateRange.range,
    page: filters.page,
    sortingColumn: filters.sortingColumn.columnName,
    sortingDirection: filters.sortingColumn.direction,
    name: filters.name,
  };
}

export class ListCandidateService {
  public static list(filterStates: FilterStates): Promise<ListCandidates> {
    return ApiService.get<ListCandidates>(
      '/api/v1/applicant_tracking/list_candidates',
      Object.fromEntries(
        mapToQueryParams(filterStates).map(({ key, value }) => [key, value]),
      ),
    );
  }

  public static newList(
    filterStates: CandidateListingFilters,
  ): Promise<ListCandidates> {
    return ApiService.get<ListCandidates>(
      '/api/v1/applicant_tracking/list_candidates',
      snakecaseKeys(mapToParams(filterStates)),
    );
  }
}
