import React from 'react';
import { Col } from 'reactstrap';
import { CandidateListingFilters } from '../../Utils';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { humanize } from '../../../../../../../../utils/humanize';

const CANDIDATE_STATUS_OPTIONS = ['Active', 'Draft', 'Hired', 'Rejected'];

interface PropTypes {
  isLoading: boolean;
  filters: CandidateListingFilters;
  setFilters: (filters: CandidateListingFilters) => void;
}

function buildOptionFromStatus(status: string) {
  if (!status) return null;

  return { label: status, value: status.toLowerCase() };
}

export function StatusFilter(props: PropTypes) {
  return (
    <Col xs='3'>
      <LabelledSelect
        options={CANDIDATE_STATUS_OPTIONS.map(buildOptionFromStatus)}
        selected={buildOptionFromStatus(humanize(props.filters.status))}
        disabled={props.isLoading}
        label='Status'
        labelClassName='fs-5 fw-semibold'
        onChange={(option: SelectOption) =>
          props.setFilters({ ...props.filters, status: option?.value })
        }
      />
    </Col>
  );
}
