import React from 'react';
import { Select } from '../../../../../../../../components/Select';

interface PropTypes {}

export function SegmentSelect(_props: PropTypes) {
  return (
    <div className='ms-3'>
      {/* Placeholder */}
      <Select options={[]} selected={null} />
    </div>
  );
}
