import React from 'react';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { mapResultToSelectOption } from '../../../../../../../../utils/mapResultToSelectOption';
import { JobStageService } from '../../../../../../../../services/v1/applicant_tracking/JobStageService';
import { CandidateListingFilters } from '../../Utils';
import { Col } from 'reactstrap';

interface PropTypes {
  isLoading: boolean;
  filters: CandidateListingFilters;
  setFilters: (filters: CandidateListingFilters) => void;
}

export function JobStagesFilter(props: PropTypes) {
  const loadJobStages = (name: string, page: number) =>
    mapResultToSelectOption(
      JobStageService.list({ name, page }),
      (filter) => filter.jobStages,
      false,
    );

  return (
    <Col xs='3'>
      <MultiSelectAsyncFilter
        isMulti
        label='Job Stage'
        placeholder='Select stages'
        loadOptions={loadJobStages}
        onChange={(jobStages) =>
          props.setFilters({ ...props.filters, jobStages })
        }
        className='ps-0 fs-5 fw-semibold'
        disabled={props.isLoading}
        selected={props.filters?.jobStages || []}
      />
    </Col>
  );
}
