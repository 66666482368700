import React from 'react';
import { MultiPaginatedSelect } from './MultiPaginatedSelect';
import classNames from 'classnames';
import { SelectOption } from '.';

interface AdditionalType {
  page: number;
}

interface LoadOptionsResponse {
  options: SelectOption[];
  hasMore: boolean;
  additional?: AdditionalType;
}

export type LoadOptionsType = (
  search: string,
  loadedOptions: SelectOption[],
  additional: AdditionalType,
) => Promise<LoadOptionsResponse>;

interface PropTypes {
  label: string;
  max: number;
  selectedItems: SelectOption[];
  additional: AdditionalType;
  loadOptions: LoadOptionsType;
  setSelectedItems: (opts: SelectOption[]) => void;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  placeholder?: string;
}

export function LabelledMultiPaginatedSelect(props: PropTypes) {
  return (
    <>
      <div
        className={classNames(
          'mb-2 d-flex fs-5 flex-nowrap align-items-end',
          props.className,
        )}
      >
        <>
          <label className='text-truncate'>{props.label}</label>
          {props.mandatory && <span className='ps-1 text-danger'>*</span>}
        </>
        {props.max && (
          <small className='ms-auto text-muted text-nowrap'>
            Max. {props.max}
          </small>
        )}
      </div>
      <MultiPaginatedSelect
        additional={props.additional}
        isMulti={true}
        danger={props.danger}
        value={props.selectedItems}
        loadOptions={props.loadOptions}
        onChange={(opts) => {
          if (!props.max || opts.length <= props.max) {
            props.setSelectedItems(opts as SelectOption[]);
          }
        }}
        debounceTimeout={300}
        placeholder={props.placeholder ?? `All ${props.label}`}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isDisabled={props.disabled}
      />
    </>
  );
}
