import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { PageTitle } from '../../../components/PageTitle';
import { EmailTemplateService } from '../../../services/EmailTemplateService';
import { EmailTemplate } from '../../../entities/EmailTemplate';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Body } from './Body';
import { UpsertEmailTemplateDto } from './UpsertEmailTemplateDto';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormErrorMessage } from '../../../components/FormErrorMessage';
import { Card } from '../../../components/Card';
import { Footer } from './Footer';
import { AttachmentDto } from '../../../services/dtos/EmailDto';
import { FileContent, useImperativeFilePicker } from 'use-file-picker';
import { EmailSizeValidator, fileSizeBytes } from '../../../utils/emails';
import { ApiError } from '../../../services/ApiService/errors/ApiError';
import { getRedirectUriQueryParam } from '../../../utils/url';

const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/email_templates';

interface PropTypes {
  attachmentsEnabled: boolean;
  edit: boolean;
  emailTemplate: EmailTemplate;
}

export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILES = 6;

function getInitEmailTemplate(
  emailTemplate?: EmailTemplate,
): UpsertEmailTemplateDto {
  return {
    id: emailTemplate?.id,
    name: emailTemplate?.name,
    emailTemplateReferenceId: emailTemplate?.emailTemplateReferenceId,
    subject: emailTemplate?.subject,
    subjectText: emailTemplate?.subjectText,
    template: emailTemplate?.template,
    templateHtml: emailTemplate?.templateHtml,
    attachments: emailTemplate?.attachments,
  };
}

function Title(props: { edit: boolean; name?: string }) {
  if (props.edit) {
    return (
      <PageTitle
        text={
          <>
            <span>Edit / </span>
            <span className='text-dark-200'>{props.name}</span>
          </>
        }
      />
    );
  } else {
    return <PageTitle text={'Add Email Template'} />;
  }
}

export default function SetupEmailTemplatePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    setError,
  } = useForm<UpsertEmailTemplateDto>({
    mode: 'onChange',
    defaultValues: getInitEmailTemplate(props.emailTemplate),
  });

  const onSubmit = handleSubmit(
    async (
      data: UpsertEmailTemplateDto,
      _event: React.SyntheticEvent<any, SubmitEvent>,
    ) => {
      setIsLoading(true);

      try {
        await EmailTemplateService.upsert(data);

        window.location.href = REDIRECT_URI;
      } catch (e: unknown) {
        setError('name', {
          type: 'unique',
          message: (e as ApiError).body.message,
        });
      }

      setIsLoading(false);
    },
  );

  const { fields, append, remove } = useFieldArray({
    name: 'attachments',
    control: control,
  });

  const handleRemoveField = (index: number) => {
    const toDelete = fields[index];
    const plainFileToDelete = plainFiles.find(
      (i) => i.name === toDelete.filename,
    );

    remove(index);

    if (plainFileToDelete != null) {
      removeFileByReference(plainFileToDelete);
    }
  };

  const [
    openFileSelector,
    {
      filesContent,
      errors: filePickerErrors,
      plainFiles,
      removeFileByReference,
      clear,
    },
  ] = useImperativeFilePicker({
    multiple: true,
    readAs: 'DataURL',
    maxFileSize: MAX_FILE_SIZE_MB,
    readFilesContent: true,
    limitFilesConfig: {
      max: MAX_FILES - fields.filter((i) => i.id != null).length,
    },
    validators: [new EmailSizeValidator(fileSizeBytes(fields))],
  });

  useEffect(() => {
    filesContent.forEach((fileContent: FileContent, index: number) => {
      if (fields.find((i) => i.filename === fileContent.name) == null) {
        append({
          filename: fileContent.name,
          contentBase64: fileContent.content,
          size: plainFiles[index].size,
        });
      }
    });
  }, [filesContent]);

  return (
    <Row className='row g-3 mb-4 align-items-center justify-content-between'>
      <FormErrorMessage error={errors} />
      <Title edit={props.edit} name={props.emailTemplate?.name} />
      <Card>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Body
              control={control}
              errors={errors}
              isValid={isValid}
              onCancel={() => (window.location.href = REDIRECT_URI)}
              onSubmit={onSubmit}
              register={register}
              setHtml={(html: string) => setValue('templateHtml', html)}
              setSubjectText={(subject: string) =>
                setValue('subjectText', subject)
              }
              setAttachments={(attachments: AttachmentDto[]) => {
                setValue('attachments', attachments);
                clear();
              }}
              setEmailTemplateReferenceId={(referenceId: number) =>
                setValue('emailTemplateReferenceId', referenceId)
              }
            />
            {props.attachmentsEnabled && (
              <Footer
                fields={fields}
                errors={filePickerErrors}
                onRemoveField={handleRemoveField}
                openFileSelector={openFileSelector}
              />
            )}
          </>
        )}
      </Card>
    </Row>
  );
}
