import React from 'react';
import { IconButton } from '../../../../../../../../components/IconButton';

interface PropTypes {}

export function AddFilterButton(_props: PropTypes) {
  return (
    <>
      <IconButton
        iconName='bi-plus-circle'
        buttonText='Add Filter'
        color='info'
      />
    </>
  );
}
