import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import styles from './styles.module.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { dateRangeToString } from '../../utils/dateRangeToString';
import { stringToDateRange } from '../../utils/stringToDateRange';
import 'bootstrap-daterangepicker/daterangepicker.css';

export enum DateRange {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  MonthToDate = 'month_to_date',
  LastMonth = 'last_month',
  Last30Days = 'last_30_days',
  Last90Days = 'last_90_days',
  CustomRange = 'custom_range',
}

export interface DateFilter {
  start: Moment;
  end: Moment;
  range: DateRange;
}

export function getDateFilterFromDateRange(range: DateRange): DateFilter {
  switch (range) {
    case DateRange.Today:
      return {
        start: moment(),
        end: moment(),
        range: range,
      };
    case DateRange.Yesterday:
      return {
        start: moment().subtract(1, 'days'),
        end: moment().subtract(1, 'days'),
        range: range,
      };
    case DateRange.Last7Days:
      return {
        start: moment().subtract(7, 'days'),
        end: moment(),
        range: range,
      };
    case DateRange.MonthToDate:
      return {
        start: moment().startOf('month'),
        end: moment(),
        range: range,
      };
    case DateRange.LastMonth:
      return {
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
        range: range,
      };
    case DateRange.Last30Days:
      return {
        start: moment().subtract(30, 'days'),
        end: moment(),
        range: range,
      };
    case DateRange.Last90Days:
      return {
        start: moment().subtract(90, 'days'),
        end: moment(),
        range: range,
      };
  }
}

function getDateArrayFromDateRange(range: DateRange): Date[] {
  const dateFilter = getDateFilterFromDateRange(range);

  return [dateFilter.start.toDate(), dateFilter.end.toDate()];
}

interface PropTypes {
  /** Classes to apply to the wrapping element. */
  className?: string;
  /** The startDate and the endDate filters. */
  dateFilter: DateFilter;
  clearable?: boolean;
  placeholder?: string;
  iconName?: string;
  /** Called when the input changes, with the newly selected options. */
  onChange: (start: Moment, end: Moment, range: DateRange) => void;
}

function isEmptyDateFilter(dateFilter: DateFilter) {
  return dateFilter?.start == null || dateFilter?.end == null;
}

function Placeholder(props: { dateFilter: DateFilter; placeholder?: string }) {
  if (!isEmptyDateFilter(props.dateFilter)) {
    return (
      <>
        {props.dateFilter?.start.format('DD/MM/YYYY') +
          ' - ' +
          props.dateFilter?.end.format('DD/MM/YYYY')}
      </>
    );
  }
  return <>{props.placeholder ?? 'Choose a date'}</>;
}

function updateSelectedDateRangeClass(props: PropTypes) {
  const rangeStr = dateRangeToString(props.dateFilter?.range);
  const parent = document.querySelector('.daterangepicker > .ranges');

  // Remove active class
  parent?.querySelector('.active')?.classList.remove('active');

  if (isEmptyDateFilter(props.dateFilter)) {
    return;
  }

  // Add active class to the current selected range
  parent
    ?.querySelector(`[data-range-key='${rangeStr}']`)
    ?.classList.add('active');
}

export function DateRangePicker(props: PropTypes) {
  const [state, setState] = useState(props.dateFilter);
  const placeholderColor = isEmptyDateFilter(props.dateFilter)
    ? 'text-dark-200'
    : 'text-dark-500';

  // Propagate the state up when it changes.
  useEffect(() => {
    props.onChange(state?.start, state?.end, state.range);
  }, [state]);

  // Change the active class on the DOM, on the first render.
  useEffect(() => {
    updateSelectedDateRangeClass(props);
  }, []);

  const onCancel = (_e, picker: any) => {
    if (props.clearable) {
      picker.setStartDate(moment());
      picker.setEndDate(moment());
      setState({ start: null, end: null, range: null });
    }
  };

  return (
    <ReactDateRangePicker
      initialSettings={{
        startDate: props.dateFilter?.start?.toDate(),
        endDate: props.dateFilter?.end?.toDate(),
        alwaysShowCalendars: props.clearable,
        ranges: {
          Today: getDateArrayFromDateRange(DateRange.Today),
          Yesterday: getDateArrayFromDateRange(DateRange.Yesterday),
          'Last 7 Days': getDateArrayFromDateRange(DateRange.Last7Days),
          'Month to date': getDateArrayFromDateRange(DateRange.MonthToDate),
          'Last Month': getDateArrayFromDateRange(DateRange.LastMonth),
          'Last 30 Days': getDateArrayFromDateRange(DateRange.Last30Days),
          'Last 90 Days': getDateArrayFromDateRange(DateRange.Last90Days),
        },
        locale: {
          ...(props.clearable && { cancelLabel: 'Clear' }),
        },
      }}
      onApply={(_e, picker: any) => {
        setState({
          start: picker.startDate.utc(true),
          end: picker.endDate.utc(true),
          range: stringToDateRange(picker.chosenLabel),
        });
      }}
      onShow={() => {
        updateSelectedDateRangeClass(props);
      }}
      onCancel={onCancel}
    >
      <button
        id='reportrange'
        className={classNames(
          'btn border-primary bg-white text-nowrap',
          styles.inputDateRangePicker,
          props.className,
        )}
      >
        <i
          className={classNames(
            `bi ${props.iconName ?? 'bi-calendar'} pe-2 ${styles.bi}`,
            placeholderColor,
          )}
        />
        <span className={classNames(placeholderColor, 'fw-normal')}>
          <Placeholder dateFilter={state} placeholder={props.placeholder} />
        </span>
      </button>
    </ReactDateRangePicker>
  );
}
