import React, { useState } from 'react';
import { FiltersHeader } from './FiltersHeader';
import { FiltersBody } from './FiltersBody';
import { CandidateListingFilters } from './Utils';

interface PropTypes {
  filters: CandidateListingFilters;
  onSearch: () => void;
  setFilters: (filters: CandidateListingFilters) => void;
}

export function ListCandidatesFilters(props: PropTypes) {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  return (
    <div className='p-4'>
      <FiltersHeader
        isFiltersOpen={isFiltersOpen}
        filters={props.filters}
        setIsFiltersOpen={setIsFiltersOpen}
      />
      {isFiltersOpen && (
        <FiltersBody
          filters={props.filters}
          onSearch={props.onSearch}
          setFilters={props.setFilters}
        />
      )}
    </div>
  );
}
