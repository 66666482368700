import _ from 'lodash';
import { DateFilter } from '../../../../../../../components/DateRangePicker';
import { SelectOption } from '../../../../../../../components/Select';
import { SortingColumn } from '../../../../../../../utils/sorting';

export interface CandidateListingFilters {
  status: string;
  jobStages: SelectOption[];
  jobs: SelectOption[];
  dateRange: DateFilter;
  page?: number;
  sortingColumn?: SortingColumn;
  name?: string;
}

export const DEFAULT_FILTER_STATE: CandidateListingFilters = {
  status: null,
  jobStages: [],
  jobs: [],
  dateRange: {
    start: null,
    end: null,
    range: null,
  },
  sortingColumn: {
    columnName: 'updated_at',
    direction: 'desc',
  },
  page: 1,
};

export function resetPageIfFiltersChanged(
  previousFilterStates: CandidateListingFilters,
  currentFilterStates: CandidateListingFilters,
  setFilterStates: (filters: CandidateListingFilters) => void,
): void {
  const previousFiltersWithoutPage = _.omit(previousFilterStates, 'page');
  const currentFiltersWithoutPage = _.omit(currentFilterStates, 'page');

  if (
    areFiltersDifferent(currentFiltersWithoutPage, previousFiltersWithoutPage)
  ) {
    setFilterStates({ ...currentFilterStates, page: 1 });
  }
}

export function areFiltersDifferent(object1, object2): boolean {
  return JSON.stringify(object1) !== JSON.stringify(object2);
}

export function countFilledFilters(filters: CandidateListingFilters): number {
  let count = 0;

  const multiSelectFilters = [filters.jobStages, filters.jobs];
  const singleValueFilters = [filters.status, filters.name];

  if (filters.dateRange.start && filters.dateRange.end) count++;

  multiSelectFilters.forEach((filter) => {
    if (filter.length > 0) count++;
  });

  singleValueFilters.forEach((filter) => {
    if (filter) count++;
  });

  return count;
}
