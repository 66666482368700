import React from 'react';
import { Button } from 'reactstrap';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { IconButton } from '../../../../../../../../components/IconButton';
import { CandidateListingFilters, countFilledFilters } from '../../Utils';

interface PropTypes {
  isFiltersOpen: boolean;
  filters: CandidateListingFilters;
  setIsFiltersOpen: (value: boolean) => void;
}

interface ButtonPropTypes {
  filters: CandidateListingFilters;
  onClick: () => void;
}

function OpenButton(props: ButtonPropTypes) {
  const count = countFilledFilters(props.filters);

  return (
    <Button color='secondary align-center' onClick={props.onClick}>
      {count > 0 && (
        <Chip color={ChipColor.Danger500} size='xs' className='me-2'>
          {count}
        </Chip>
      )}
      Open Filters
    </Button>
  );
}

function CloseButton(props: ButtonPropTypes) {
  return (
    <IconButton
      color='secondary align-center'
      iconName='bi-x'
      buttonText='Close Filters'
      onClick={props.onClick}
    />
  );
}

export function ToggleFiltersButton(props: PropTypes) {
  return (
    <>
      {props.isFiltersOpen ? (
        <CloseButton
          filters={props.filters}
          onClick={() => props.setIsFiltersOpen(false)}
        />
      ) : (
        <OpenButton
          filters={props.filters}
          onClick={() => props.setIsFiltersOpen(true)}
        />
      )}
    </>
  );
}
