import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/Card';
import { IconButton } from '../../../../components/IconButton';
import { PDFViewer } from '../../../../components/PDFViewer';
import { Application } from '../../../../entities/Application';
import { RedactedResumeService } from '../../../../services/v1/applicant_tracking/RedactedResumeService';
import { RedactingLoadingState } from '../../../applicant_tracking/RedactingLoadingState';
import { Size } from '../../../../utils/types/Size';
import { useScreenSize } from '../../../../utils/useScreenSize';

interface PropTypes {
  application: Application;
}

const HEIGHTS: Record<Size, string> = {
  xxxl: '1020px',
  xxl: '920px',
  xl: '800px',
  lg: '512px',
  md: '320px',
  sm: '200px',
  xs: '150px',
};

export function ResumePanel(props: PropTypes) {
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  const [applicationResumeUrl, setApplicationResumeUrl] =
    useState<string>(null);
  const screenSize = useScreenSize();

  useEffect(() => {
    (async () => {
      if (props.application.applicationResume != null) {
        if (
          props.application.applicationResume.url == null &&
          props.application.redacted
        ) {
          const resume = await RedactedResumeService.show(
            props.application.applicationResume?.id,
          );

          setApplicationResumeUrl(resume.url);
        } else {
          setApplicationResumeUrl(props.application.applicationResume.url);
        }
      }

      setIsLoadingResume(false);
    })();
  }, []);

  if (props.application.applicationResume == null) {
    return;
  }

  if (applicationResumeUrl == null && isLoadingResume) {
    return <RedactingLoadingState redacted={props.application.redacted} />;
  }

  return (
    <Card>
      <div className='text-end'>
        <IconButton
          iconName='bi-box-arrow-up-right'
          buttonText='View CV'
          buttonClass='mb-1'
          color='borderless'
          onClick={() => window.open(applicationResumeUrl, '_blank')}
        />
      </div>
      <div style={{ height: HEIGHTS[screenSize], overflowY: 'scroll' }}>
        <PDFViewer
          loadingElement={
            <RedactingLoadingState redacted={props.application.redacted} />
          }
          url={applicationResumeUrl}
        />
      </div>
    </Card>
  );
}
