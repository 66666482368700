import { getQueryParam } from '../../../url';
import {
  CareersJobBoardParams,
  ORGANIC_POSTING_PARAM,
} from '../careers_job_board_params';

export function parseJobBoardParams(): CareersJobBoardParams {
  const campaign = getQueryParam('vq_campaign');
  let source = getQueryParam('vq_source') ?? getQueryParam('sl_source');

  if (source === 'sl_careers' || source === 'website') {
    return { source, campaign: null, provider: null };
  }

  if (source == 'organic-posting-network') {
    source = ORGANIC_POSTING_PARAM;
  }

  // Source is required, thus not empty
  // If value is organic-posting-network, then no campaign id is expected
  // Else, then a campaign is expected, though not required
  if (source == null) return null;

  const provider = 'vonq';

  return {
    source,
    campaign,
    provider,
  };
}
