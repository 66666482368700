import { GlobalState, useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { Control, useForm, UseFormSetValue } from 'react-hook-form';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { JobService } from '../../../../../services/applicant_tracking/JobService';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { JobPageGroupHeader } from '../JobPageGroupHeader';
import { JobWorkflowHeader } from './JobWorkflowHeader';
import { JobWorkflowPipeline } from './JobWorkflowPipeline';
import { JobAndJobPost, initCreateJobDto } from '..';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { getReferrer } from '../referrers';
import { FormErrorMessage } from '../../../../../components/FormErrorMessage';
import { handleFormErrors } from '../../../../../services/ApiService/handler';
import { LoadingStatus } from '../../../../../utils/types/LoadingStatus';
import { EmptyState } from '../../../../../components/EmptyState';
import { JobStageTemplateInput } from '../../../../PlanTemplates/SetupPlanTemplatePage';
import { JobStageDetail } from './JobWorkflowPipeline/WorkflowDetail/JobStageDetail';
import { JobWorkflowTriggers } from './JobWorkflowTriggers';
import { Trigger } from '../../../../../entities/applicant_tracking/Trigger';
import { validateTriggers } from './JobWorkflowTriggers/validateTriggers';
import { replaceFinalSegment } from '../../../../../utils/url';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../../../components/Card';
import { HrisProvider } from '../../../../../enums/IntegrationProvider/HrisProvider';
import { PlanTemplateService } from '../../../../../services/v1/applicant_tracking/PlanTemplateService';
import { PlanTemplateList } from '../../../../../entities/v1/applicant_tracking/PlanTemplateList';
import { PlanTemplate as OldPlanTemplate } from '../../../../../entities/applicant_tracking/PlanTemplate';
import { PlanTemplateService as OldPlanTemplateService } from '../../../../../services/applicant_tracking/PlanTemplateService';

interface PropTypes {
  changeLiveWorkflow: boolean;
  initJobDto: CreateJobDto;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  setupStep: number;
  onSubmit: (state: CreateJobDto) => Promise<JobAndJobPost>;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  updateLocalStorage: (state: GlobalState, payload: any) => GlobalState;
  hrisProvider?: HrisProvider;
  selfSchedulingAutomationEnabled: boolean;
}

interface JobWorkflowPropTypes {
  planTemplateList: PlanTemplateList;
  selected: OldPlanTemplate | undefined;
  formControl: Control<CreateJobDto>;
  jobDetails: JobDetails;
}

interface BodyPropTypes extends JobWorkflowPropTypes {
  changeLiveWorkflow: boolean;
  hrisProvider: HrisProvider;
  loadingStatus: LoadingStatus;
  focusedJobStage: JobStageTemplateInput;
  isEditFlow: boolean;
  triggers: Trigger[];
  triggersEnabled: boolean;
  initJobDto: CreateJobDto;
  selfSchedulingAutomationEnabled: boolean;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
  setTriggers: (triggers: Trigger[]) => void;
  setTriggersEnabled: (enabled: boolean) => void;
  setValue: UseFormSetValue<CreateJobDto>;
}

function JobWorkflow(props: BodyPropTypes) {
  return (
    <>
      <JobPageGroupHeader
        lastElement={false}
        title='Job Workflow'
        description='Select job workflow and setup your process'
        mandatory={false}
        className='mt-2'
      >
        <JobWorkflowPipeline
          jobId={props.initJobDto?.jobId}
          focusedJobStage={props.focusedJobStage}
          formControl={props.formControl}
          jobDetails={props.jobDetails}
          planTemplateList={props.planTemplateList}
          planTemplate={props.selected}
          changeLiveWorkflow={props.isEditFlow && props.changeLiveWorkflow}
          setValue={props.setValue}
          setFocusedJobStage={props.setFocusedJobStage}
        />
      </JobPageGroupHeader>
      <JobPageGroupHeader
        lastElement
        title='Job Workflow Automation'
        description='Define automated actions for this job'
        mandatory={false}
        className='mt-2'
      >
        <JobWorkflowTriggers
          formControl={props.formControl}
          planTemplate={props.selected}
          triggers={props.triggers}
          locationId={props.initJobDto.locationId}
          applicationFormTemplateId={parseInt(
            props.initJobDto.applicationFormTemplateId,
          )}
          triggersEnabled={props.triggersEnabled}
          setTriggersEnabled={props.setTriggersEnabled}
          setTriggers={props.setTriggers}
          hrisProvider={props.hrisProvider}
          selfSchedulingAutomationEnabled={
            props.selfSchedulingAutomationEnabled
          }
        />
      </JobPageGroupHeader>
    </>
  );
}

function Body(props: BodyPropTypes) {
  switch (props.loadingStatus) {
    case 'LOADING':
      return <LoadingSpinner />;
    case 'SUCCESS':
      return <JobWorkflow {...props} />;
    default:
      return (
        <EmptyState
          title='We have encountered an error'
          text={
            <span>
              An error occurred please contact support at{' '}
              <a className='link-info' href='mailto:support@screenloop.com'>
                support@screenloop.com
              </a>
            </span>
          }
          cardless={true}
        />
      );
  }
}

export function SetupJobWorkflowPage(props: PropTypes) {
  const navigate = useNavigate();
  const updateLocalStorage = props.updateLocalStorage;
  const { actions, state } = useStateMachine({ updateLocalStorage });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState,
    reset,
    getValues,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<CreateJobDto>({
    mode: 'onChange',
    defaultValues: props.initJobDto,
  });

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('LOADING');
  const [planTemplateList, setPlanTemplateList] = useState<PlanTemplateList>();
  const [selected, setSelected] = useState<OldPlanTemplate>(null);

  const watchJobWorkflowId = watch('jobWorkflowId');
  useEffect(() => {
    async function load() {
      if (watchJobWorkflowId) {
        const planTemplate = await OldPlanTemplateService.show(
          watchJobWorkflowId,
        );
        setSelected(planTemplate);
      }
    }

    load();
  }, [watchJobWorkflowId]);

  const [triggers, setTriggers] = useState<Trigger[]>(
    props.initJobDto.triggers.map((trigger) => ({
      ...trigger,
      uniqueKey: window.crypto.randomUUID(),
    })),
  );
  const [triggersEnabled, setTriggersEnabled] = useState(triggers.length > 0);

  useEffect(() => {
    reset(props.initJobDto);
  }, [props.initJobDto]);

  useEffect(
    () => validateTriggers(triggers, setError, clearErrors, trigger),
    [triggers],
  );

  const submitData = async (data: CreateJobDto) => {
    const enabledTriggers = triggersEnabled ? triggers : [];

    try {
      await props.onSubmit({ ...data, triggers: enabledTriggers });
      actions.updateLocalStorage({ ...getValues(), triggers: enabledTriggers });

      return true;
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }

    return false;
  };

  const postSave = () => {
    const nextStepUrl = replaceFinalSegment('organic_posts');

    props.setSetupStep(props.setupStep + 1, state.setupJob);
    navigate(nextStepUrl);
  };

  const onSubmit = handleSubmit(async (data: CreateJobDto) => {
    const isSuccessful = await submitData(data);
    if (!isSuccessful) return;

    await JobService.publish(state.setupJob.jobId.toString(), true);
    postSave();
  });

  const onSave = handleSubmit(async (data: CreateJobDto) => {
    const isSuccessful = await submitData(data);
    if (!isSuccessful) return;

    await JobService.submit(state.setupJob.jobId.toString());
    postSave();
  });

  const onNavigate = () => {
    const enabledTriggers = triggersEnabled ? triggers : [];

    actions.updateLocalStorage({ ...getValues(), triggers: enabledTriggers });
  };

  useEffect(() => {
    async function load() {
      try {
        const planTemplateList = await PlanTemplateService.list({
          publishState: 'published',
        });
        setPlanTemplateList(planTemplateList);
        setLoadingStatus('SUCCESS');
      } catch (e) {
        console.error(e);
        setLoadingStatus('ERROR');
      }
    }

    load();
  }, []);

  const [focusedJobStage, setFocusedJobStage] =
    useState<JobStageTemplateInput>(null);

  return (
    <>
      <Card>
        <form>
          <FormErrorMessage error={formState.errors} />
          <JobWorkflowHeader
            formState={formState}
            {...props}
            onCancel={() => {
              actions.updateLocalStorage(initCreateJobDto);
              window.location.href = getReferrer();
            }}
            onSave={onSave}
            onSubmit={onSubmit}
            jobDetails={props.jobDetails}
            onNavigate={onNavigate}
          />
          <Body
            changeLiveWorkflow={props.changeLiveWorkflow}
            loadingStatus={loadingStatus}
            formControl={control}
            planTemplateList={planTemplateList}
            selected={selected}
            jobDetails={props.jobDetails}
            isEditFlow={props.isEditFlow}
            focusedJobStage={focusedJobStage}
            triggers={triggers}
            triggersEnabled={triggersEnabled}
            initJobDto={props.initJobDto}
            hrisProvider={props.hrisProvider}
            selfSchedulingAutomationEnabled={
              props.selfSchedulingAutomationEnabled
            }
            setTriggers={setTriggers}
            setFocusedJobStage={setFocusedJobStage}
            setTriggersEnabled={setTriggersEnabled}
            setValue={setValue}
          />
        </form>
      </Card>
      {focusedJobStage && (
        <JobStageDetail
          jobStage={focusedJobStage}
          setFocusedJobStage={setFocusedJobStage}
        />
      )}
    </>
  );
}
